import React from 'react';

const AirFryer  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="62" viewBox="0 0 51 62">
  <g id="AirFryer" transform="translate(-943 -1126)">
    <g id="noun_Air_Fryer_252770" data-name="noun_Air Fryer_252770" transform="translate(926.475 1115.985)">
      <g id="Group_445" data-name="Group 445" transform="translate(18.712 39.607)">
        <path id="Path_1073" data-name="Path 1073" d="M42.494,79.683c-14.658,0-18.112-2.686-18.419-2.993-.153-.153-2.456-1.919-4.374-7.6-1.535-4.681-.921-14.582-.537-18.649a2.371,2.371,0,0,1,.844-1.535,1.9,1.9,0,0,1,1.688-.384c2.3.46,9.056,1.535,20.721,1.381a140.4,140.4,0,0,0,20.644-1.612h.23a2.022,2.022,0,0,1,1.612.46,2.389,2.389,0,0,1,.844,1.458h0c.307,3.223,1.074,14.121-.537,18.956-1.919,5.679-4.221,7.444-4.451,7.674C60.452,77.074,56.922,79.683,42.494,79.683ZM20.949,49.906c-1.471.692-1.458,14.48,0,18.7,1.688,4.988,3.912,6.859,3.988,6.935s3.819,2.9,17.479,2.9c13.507,0,17.692-2.823,17.692-2.9.077,0,2.277-1.947,3.966-6.935A42.223,42.223,0,0,0,65.21,57.275a35.152,35.152,0,0,0-.713-6.832,1.63,1.63,0,0,0-1.625-1.252,146.715,146.715,0,0,1-20.455,1.624C30.752,50.968,22.42,49.214,20.949,49.906Z" transform="translate(-18.712 -48.275)"/>
      </g>
      <g id="Group_446" data-name="Group 446" transform="translate(22.009 40.076)">
        <path id="Path_1074" data-name="Path 1074" d="M43.493,75.9c-13.047,0-15.809-2.353-15.963-2.428-.077-.076-2-1.518-3.684-6.375-1.381-4.1-.691-13.356-.46-16.164a.833.833,0,0,1,.46-.759,1.076,1.076,0,0,1,.844-.152c2.149.455,8.212,1.366,18.726,1.214a136.528,136.528,0,0,0,18.572-1.442h.23a.873.873,0,0,1,.844.228.931.931,0,0,1,.384.759h0c.23,2.808,1,12.294-.46,16.392-1.612,4.781-3.53,6.3-3.607,6.3C59.226,73.62,56.309,75.9,43.493,75.9ZM24.537,50.17a35.2,35.2,0,0,0-.691,6.081c-.176,3.594-.023,8.566.691,10.766,1.612,4.705,3.348,5.956,3.348,5.956.077,0,2.715,2.092,15.532,2.092,12.663,0,15.385-1.944,15.462-2.02,0,0,1.806-1.324,3.418-6.029.724-2.187.9-6.259.767-10.191-.1-2.969-.459-5.869-.568-6.808,0-.228.153.228,0,.152-.153-.152.031,0-.2,0h-.23a124.625,124.625,0,0,1-18.649,1.442A113.111,113.111,0,0,1,24.537,50.17Z" transform="translate(-23.009 -49.763)"/>
      </g>
      <path id="Path_1075" data-name="Path 1075" d="M65.612,35.712,60.854,13.84a2.61,2.61,0,0,0-2.379-2.072c-2.456-.153-7.444-.537-11.435-.767a9.437,9.437,0,0,1,2.236,1.661s9.738-.046,10.129.943c.309.78.343.316,2.714,11.161.552,2.525,1.275,6.4,2.332,10.947a2.334,2.334,0,0,1-.223,1.41,2.091,2.091,0,0,1-1.207.662c-4.374.46-14.907,1.65-20.356,1.65-12.893,0-18.514-.967-20.663-1.5-.23-.077-1.643-.435-1.5-1.666S24.788,14.33,24.788,14.33a1.569,1.569,0,0,1,1.147-1.418c.985-.217,8.2-.251,8.2-.251s1.774-1.047,3-1.661c-4.144.23-8.826.614-11.2.767a2.542,2.542,0,0,0-2.379,2.149l-4.067,22.1a2.661,2.661,0,0,0,2.072,3.07c2.226.46,7.981,1.3,21.1,1.3,5.526,0,16.27-1.074,20.721-1.535a2.428,2.428,0,0,0,1.842-1.074A2.7,2.7,0,0,0,65.612,35.712Z" transform="translate(-0.172 0)"/>
      <path id="Path_1076" data-name="Path 1076" d="M46.993,16.412a11.268,11.268,0,0,0,6.293-1.919A8.014,8.014,0,0,0,46.993,11.5,8.129,8.129,0,0,0,40.7,14.493,11.268,11.268,0,0,0,46.993,16.412Z" transform="translate(-5.113 -0.116)"/>
      <path id="Path_1077" data-name="Path 1077" d="M47.107,25.1a6.883,6.883,0,0,0-3.761,12.663v5.328h7.444V37.84a6.869,6.869,0,0,0,3.147-5.833A6.766,6.766,0,0,0,47.107,25.1Zm-.153,10.821a3.93,3.93,0,0,1-3.914-3.914,3.83,3.83,0,0,1,3.377-3.837v-.614a.46.46,0,1,1,.921,0v.614a3.83,3.83,0,0,1,3.377,3.837A3.709,3.709,0,0,1,46.954,35.921Z" transform="translate(-4.997 -3.279)"/>
      <path id="Path_1078" data-name="Path 1078" d="M65.626,29.828H64.014a.66.66,0,0,1-.614-.614h0a.66.66,0,0,1,.614-.614h1.612a.66.66,0,0,1,.614.614h0A.66.66,0,0,1,65.626,29.828Z" transform="translate(-10.392 -4.093)"/>
      <path id="Path_1079" data-name="Path 1079" d="M65.626,32.728H64.014a.66.66,0,0,1-.614-.614h0a.66.66,0,0,1,.614-.614h1.612a.66.66,0,0,1,.614.614h0A.605.605,0,0,1,65.626,32.728Z" transform="translate(-10.392 -4.767)"/>
      <path id="Path_1080" data-name="Path 1080" d="M48.621,72.781h-1A3.243,3.243,0,0,1,44.4,69.557V51.46h7.444V69.634A3.178,3.178,0,0,1,48.621,72.781Z" transform="translate(-5.974 -9.517)"/>
    </g>
    <rect id="Rectangle_57" data-name="Rectangle 57" width="51" height="62" transform="translate(943 1126)" fill="none"/>
  </g>
</svg>

  )
}

export default AirFryer;
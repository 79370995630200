import React from 'react';

const Pyrex  = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="98.37" height="63.109" viewBox="0 0 98.37 63.109">
  <g id="Pyrex" transform="translate(-674.002 -1125)">
    <path id="Path_1062" data-name="Path 1062" d="M0,0H91V62H0Z" transform="translate(680 1125)" fill="none"/>
    <g id="Group_444" data-name="Group 444" transform="translate(1 -131)">
      <path id="Exclusion_12" data-name="Exclusion 12" d="M9816.577-4463.891c-.185,0-.309-.006-.342-.008h-63.4c-.158,0-3.9-.014-5.874-1.268a5.494,5.494,0,0,1-2.244-3.879L9742-4483h83.273c-.2,1.1-.41,2.213-.612,3.293l-.053.281-.014.074c-.682,3.638-1.324,7.074-1.666,9.616a6.47,6.47,0,0,1-2.1,4.569A6.881,6.881,0,0,1,9816.577-4463.891Zm7.25-17.937-80.414.241,2.353,12.061a4.73,4.73,0,0,0,2.18,3.417c1.925,1.1,5.558,1.117,5.711,1.117H9815.3c.033,0,.151.007.33.007a7.207,7.207,0,0,0,4.138-1.124,5.485,5.485,0,0,0,2.039-4.029c.356-2.4,1.094-7.1,2.022-11.689Z" transform="translate(-9061 5783)"/>
      <path id="Exclusion_13" data-name="Exclusion 13" d="M9831.784-4469.737h-80.3l-.054-.092,80.354.085,0,.006Zm1.423,0-1.421,0,0-.013c.238-.5,1.5-3.063,2.936-4.042a7.741,7.741,0,0,1,3.92-1.021h.068l-1.985-4.611a24.238,24.238,0,0,1-4.137-.642,17.962,17.962,0,0,1-4.226-1.667H9753.79a14.016,14.016,0,0,1-3.722,1.667,16.84,16.84,0,0,1-3.721.575c-.325.021-.616.04-.917.067l-1.738,4.094h.01a10.729,10.729,0,0,1,4.137,1.26c1.629.979,3.279,3.709,3.594,4.247h-1.413a13.447,13.447,0,0,0-3.216-3.295,10.282,10.282,0,0,0-4.8-1.19l2.876-6.426c.011,0,.4.031.964.031a14.323,14.323,0,0,0,3.726-.456,14.9,14.9,0,0,0,3.977-1.833h75.377a9.417,9.417,0,0,0,3.66,1.835,24.433,24.433,0,0,0,4.771.662l3.021,6.9c-.007,0-.32-.026-.818-.026a7.678,7.678,0,0,0-4.121.945,7.076,7.076,0,0,0-2.225,2.94h0Z" transform="translate(-9069 5770)"/>
      <path id="Path_1069" data-name="Path 1069" d="M-9219.664,5500.338a19.715,19.715,0,0,1,.6-7.6,16.75,16.75,0,0,1,2.778-5.337l1.126.162a15.773,15.773,0,0,0-2.9,5.44,22.3,22.3,0,0,0-.466,7.34Z" transform="translate(9900.961 -4200.119)"/>
      <path id="Path_1070" data-name="Path 1070" d="M-9215.285,5500.338a19.715,19.715,0,0,0-.6-7.6,16.75,16.75,0,0,0-2.778-5.337l-1.126.162a15.773,15.773,0,0,1,2.9,5.44,22.3,22.3,0,0,1,.466,7.34Z" transform="translate(9978.96 -4200.119)"/>
      <path id="Path_1071" data-name="Path 1071" d="M.067,1.172A32.515,32.515,0,0,1,.5,6.894a41.8,41.8,0,0,1-.737,7.171L-1.209,14A49.817,49.817,0,0,0-.518,6.823,25.855,25.855,0,0,0-1.04,1.095Z" transform="matrix(-0.998, 0.07, -0.07, -0.998, 685.879, 1301.591)"/>
      <path id="Path_1072" data-name="Path 1072" d="M1.276,12.893a32.51,32.51,0,0,0,.436-5.722A49.142,49.142,0,0,0,1.086-.008l-.9.063a70.744,70.744,0,0,1,.5,7.187A25.851,25.851,0,0,1,.169,12.97Z" transform="matrix(0.998, 0.07, -0.07, 0.998, 758.301, 1287.476)"/>
    </g>
  </g>
</svg>

  )
}

export default Pyrex;
import React from 'react';

const Oven  = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="62" viewBox="0 0 68 62">
    <g id="Oven" transform="translate(-542 -1125)">
      <g id="noun_Oven_726406" transform="translate(534 1110)">
        <g id="Group_408" data-name="Group 408" transform="translate(13 16)">
          <path id="Path_942" data-name="Path 942" d="M64.353,17.834H61.04V16.786A.786.786,0,0,0,60.254,16H49.683a.786.786,0,0,0-.786.786v1.048H35.269V16.786A.786.786,0,0,0,34.483,16H23.911a.786.786,0,0,0-.786.786v1.048H19.812A6.82,6.82,0,0,0,13,24.646V69.188A6.82,6.82,0,0,0,19.812,76H64.354a6.82,6.82,0,0,0,6.812-6.812V24.646A6.821,6.821,0,0,0,64.353,17.834Zm5.24,51.354a5.247,5.247,0,0,1-5.24,5.24H19.812a5.247,5.247,0,0,1-5.24-5.24V30.934H69.593Zm0-39.825H14.572V24.646a5.247,5.247,0,0,1,5.24-5.24H64.354a5.246,5.246,0,0,1,5.24,5.24Z" transform="translate(-13 -16)"/>
          <circle id="Ellipse_74" data-name="Ellipse 74" cx="1.991" cy="1.991" r="1.991" transform="translate(6.958 6.909)"/>
          <circle id="Ellipse_75" data-name="Ellipse 75" cx="1.991" cy="1.991" r="1.991" transform="translate(16.786 6.909)"/>
          <circle id="Ellipse_76" data-name="Ellipse 76" cx="1.991" cy="1.991" r="1.991" transform="translate(38.015 6.909)"/>
          <circle id="Ellipse_77" data-name="Ellipse 77" cx="1.991" cy="1.991" r="1.991" transform="translate(47.843 6.909)"/>
          <path id="Path_943" data-name="Path 943" d="M23.66,71.881H63.245A4.263,4.263,0,0,0,67.5,67.623V45.091a4.263,4.263,0,0,0-4.258-4.258H23.66A4.263,4.263,0,0,0,19.4,45.091V67.624A4.263,4.263,0,0,0,23.66,71.881Zm-2.685-26.79a2.689,2.689,0,0,1,2.686-2.686H63.245a2.689,2.689,0,0,1,2.686,2.686V67.624a2.689,2.689,0,0,1-2.686,2.686H39.2L50.561,58.953a.786.786,0,1,0-1.111-1.111L36.981,70.309H23.66a2.689,2.689,0,0,1-2.686-2.686V45.091Z" transform="translate(-14.37 -21.314)"/>
          <path id="Path_944" data-name="Path 944" d="M61.876,57.727a.784.784,0,0,0,.556-.23l8.333-8.333a.786.786,0,1,0-1.111-1.111L61.32,56.386a.786.786,0,0,0,.556,1.342Z" transform="translate(-23.29 -22.809)"/>
          <path id="Path_945" data-name="Path 945" d="M35.756,54.876a.784.784,0,0,0,.556-.23l9.281-9.281a.786.786,0,0,0-1.111-1.111L35.2,53.535a.786.786,0,0,0,.556,1.342Z" transform="translate(-17.701 -21.996)"/>
          <path id="Path_946" data-name="Path 946" d="M24.645,66.437a.784.784,0,0,0,.556-.23l6.535-6.535a.786.786,0,0,0-1.111-1.111L24.089,65.1a.786.786,0,0,0,.556,1.342Z" transform="translate(-15.324 -25.058)"/>
          <path id="Path_947" data-name="Path 947" d="M52.791,23.791H44.533a.786.786,0,0,0-.786.786v4.487a.786.786,0,0,0,.786.786h8.258a.786.786,0,0,0,.786-.786V24.577A.786.786,0,0,0,52.791,23.791ZM52,28.278H45.319V25.363H52Z" transform="translate(-19.579 -17.667)"/>
        </g>
      </g>
      <rect id="Rectangle_50" data-name="Rectangle 50" width="68" height="62" transform="translate(542 1125)" fill="none"/>
    </g>
  </svg>
  )
}

export default Oven;
import React from 'react';

const PizzaMold  = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="75.767" height="62" viewBox="0 0 75.767 62">
  <g id="mold" transform="translate(-1056 -1126)">
    <g id="Group_450" data-name="Group 450" transform="translate(0 -3.94)">
      <path id="Path_1084" data-name="Path 1084" d="M1.8.08c.91.012,1.676,2.3,1.71,5.1s-.675,5.073-1.584,5.061-1.676-2.3-1.71-5.1S.888.068,1.8.08Z" transform="translate(1060.911 1165.38) rotate(21)"/>
      <g id="Group_449" data-name="Group 449" transform="translate(1060.232 1157.752)">
        <path id="Exclusion_14" data-name="Exclusion 14" d="M34.161,33.188a64.528,64.528,0,0,1-13.3-1.34,45.34,45.34,0,0,1-10.859-3.655,22.855,22.855,0,0,1-7.321-5.421A10.2,10.2,0,0,1,0,16.134,9.7,9.7,0,0,1,2.685,9.64a21.959,21.959,0,0,1,7.321-5.1C16.336,1.61,24.914,0,34.161,0S51.986,1.61,58.316,4.535a21.959,21.959,0,0,1,7.321,5.1,9.7,9.7,0,0,1,2.685,6.495,10.2,10.2,0,0,1-2.685,6.638,22.855,22.855,0,0,1-7.321,5.421,45.34,45.34,0,0,1-10.858,3.655A64.523,64.523,0,0,1,34.161,33.188ZM34.587.637c-8.721,0-16.861,1.556-22.921,4.38C5.543,7.871,2.172,11.744,2.172,15.924a9.292,9.292,0,0,0,2.547,6.134,21.65,21.65,0,0,0,6.947,5.009c6.122,2.976,14.263,4.615,22.921,4.615s16.8-1.639,22.92-4.615a21.65,21.65,0,0,0,6.947-5.009A9.292,9.292,0,0,0,67,15.924c0-4.18-3.372-8.053-9.494-10.907C51.447,2.193,43.307.637,34.587.637Z" transform="translate(0 0)"/>
      </g>
      <path id="Path_1085" data-name="Path 1085" d="M1.523-.062C2.432-.073,3.142,2.2,3.11,5s-.795,5.095-1.7,5.106S-.213,7.853-.181,5.044.613-.051,1.523-.062Z" transform="translate(1131.574 1175.14) rotate(161)"/>
    </g>
    <rect id="Rectangle_59" data-name="Rectangle 59" width="71" height="62" transform="translate(1056 1126)" fill="none"/>
  </g>
</svg>


  )
}

export default PizzaMold;
import React from 'react';

const PressionPot  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="73" height="62" viewBox="0 0 73 62">
      <g id="PressionPot" transform="translate(-787 -1125)">
        <rect id="Rectangle_54" data-name="Rectangle 54" width="73" height="62" transform="translate(787 1125)" fill="none"/>
        <g id="noun_Pressure_Cooker_3009464" data-name="noun_Pressure Cooker_3009464" transform="translate(723 1002.732)">
          <g id="Group_432" data-name="Group 432" transform="translate(64.46 123.994)">
            <g id="Group_431" data-name="Group 431" transform="translate(0)">
              <g id="Group_421" data-name="Group 421" transform="translate(4.072 22.936)">
                <path id="Path_1043" data-name="Path 1043" d="M116.441,255.861H95.56a11.59,11.59,0,0,1-10.639-6.99,11.2,11.2,0,0,1-.913-4.445V221.009a.634.634,0,0,1,1.268,0c0,.743,1.511,1.971,5.769,2.986A67.643,67.643,0,0,0,106,225.468a67.688,67.688,0,0,0,14.961-1.474c4.26-1.015,5.771-2.243,5.771-2.986a.634.634,0,0,1,1.268,0v23.417a11.189,11.189,0,0,1-.914,4.445,11.444,11.444,0,0,1-2.484,3.635,11.591,11.591,0,0,1-8.162,3.355ZM85.275,223.072v21.354A10.238,10.238,0,0,0,95.56,254.593h20.881a10.241,10.241,0,0,0,10.292-10.167V223.071a6.866,6.866,0,0,1-.734.473,18.851,18.851,0,0,1-4.743,1.683A68.945,68.945,0,0,1,106,226.737a68.9,68.9,0,0,1-15.25-1.508,18.838,18.838,0,0,1-4.742-1.683A6.821,6.821,0,0,1,85.275,223.072Z" transform="translate(-84.007 -220.375)"/>
              </g>
              <g id="Group_422" data-name="Group 422" transform="translate(4.072 22.936)">
                <path id="Path_1044" data-name="Path 1044" d="M116.441,247.809H95.56a11.59,11.59,0,0,1-10.639-6.99,11.2,11.2,0,0,1-.913-4.445V221.009a.634.634,0,0,1,1.268,0c0,.743,1.511,1.971,5.769,2.986A67.643,67.643,0,0,0,106,225.469a67.688,67.688,0,0,0,14.961-1.474c4.26-1.015,5.771-2.243,5.771-2.986a.634.634,0,0,1,1.268,0v15.365a11.189,11.189,0,0,1-.914,4.445,11.437,11.437,0,0,1-2.484,3.635,11.591,11.591,0,0,1-8.162,3.355ZM85.275,223.072v13.3A10.238,10.238,0,0,0,95.56,246.541h20.881a10.242,10.242,0,0,0,10.292-10.167v-13.3a6.866,6.866,0,0,1-.734.473,18.852,18.852,0,0,1-4.743,1.683A68.945,68.945,0,0,1,106,226.736a68.9,68.9,0,0,1-15.25-1.508,18.839,18.839,0,0,1-4.742-1.683A6.8,6.8,0,0,1,85.275,223.072Z" transform="translate(-84.007 -220.375)"/>
              </g>
              <g id="Group_423" data-name="Group 423" transform="translate(46.797 26.963)">
                <path id="Path_1045" data-name="Path 1045" d="M349.221,250.7H328.872a.634.634,0,0,1-.634-.634v-6.038a.634.634,0,0,1,.634-.634H349.22a.634.634,0,0,1,.634.634v6.038A.634.634,0,0,1,349.221,250.7Zm-19.715-1.268h19.08v-4.77h-19.08Z" transform="translate(-328.238 -243.398)"/>
              </g>
              <g id="Group_424" data-name="Group 424" transform="translate(61.038 33.001)">
                <path id="Path_1046" data-name="Path 1046" d="M416.386,285.224h-6.108a.634.634,0,0,1-.634-.634v-6.041a.634.634,0,0,1,.634-.634h6.108a.634.634,0,0,1,.634.634v6.041A.634.634,0,0,1,416.386,285.224Zm-5.474-1.268h4.84v-4.774h-4.84Z" transform="translate(-409.645 -277.914)"/>
              </g>
              <g id="Group_425" data-name="Group 425" transform="translate(0 26.963)">
                <path id="Path_1047" data-name="Path 1047" d="M65.437,250.7H61.365a.634.634,0,0,1-.634-.634v-6.038a.634.634,0,0,1,.634-.634h4.072a.634.634,0,0,1,.634.634v6.038A.634.634,0,0,1,65.437,250.7ZM62,249.436h2.8v-4.77H62Z" transform="translate(-60.731 -243.398)"/>
              </g>
              <g id="Group_426" data-name="Group 426" transform="translate(4.072 17.864)">
                <path id="Path_1048" data-name="Path 1048" d="M106,202.815a68.947,68.947,0,0,1-15.25-1.508,18.855,18.855,0,0,1-4.742-1.682c-1.328-.748-2-1.6-2-2.534,0-.889.613-1.706,1.822-2.428a16.9,16.9,0,0,1,4.316-1.636,64.046,64.046,0,0,1,14.011-1.641.634.634,0,0,1,.647.634v.864a1.2,1.2,0,0,0,2.4,0v-.864a.634.634,0,0,1,.647-.634,64.093,64.093,0,0,1,14.015,1.641,16.9,16.9,0,0,1,4.316,1.636c1.209.722,1.823,1.539,1.823,2.428,0,.933-.674,1.786-2,2.534a18.862,18.862,0,0,1-4.743,1.682A68.98,68.98,0,0,1,106,202.815Zm-2.464-10.146a61.638,61.638,0,0,0-13.073,1.585,15.7,15.7,0,0,0-3.983,1.5c-.777.464-1.2.94-1.2,1.34,0,.742,1.511,1.968,5.769,2.982A67.666,67.666,0,0,0,106,201.547a67.712,67.712,0,0,0,14.961-1.473c4.26-1.015,5.771-2.241,5.771-2.982,0-.4-.428-.876-1.2-1.34a15.706,15.706,0,0,0-3.984-1.5,61.662,61.662,0,0,0-13.076-1.585v.214a2.422,2.422,0,0,1-.727,1.732,2.467,2.467,0,0,1-3.48,0,2.426,2.426,0,0,1-.725-1.731v-.214Z" transform="translate(-84.009 -191.386)"/>
              </g>
              <g id="Group_427" data-name="Group 427" transform="translate(23.601 14.498)">
                <path id="Path_1049" data-name="Path 1049" d="M198.105,179.455a2.46,2.46,0,0,1-2.464-2.449v-2.414a2.47,2.47,0,0,1,4.207-1.735,2.424,2.424,0,0,1,.724,1.735v2.414a2.424,2.424,0,0,1-.724,1.734A2.462,2.462,0,0,1,198.105,179.455Zm0-6.045a1.19,1.19,0,0,0-1.2,1.182v2.414a1.2,1.2,0,0,0,2.4,0v-2.414A1.192,1.192,0,0,0,198.105,173.41Z" transform="translate(-195.641 -172.142)"/>
              </g>
              <g id="Group_428" data-name="Group 428" transform="translate(13.958 5.178)">
                <path id="Path_1050" data-name="Path 1050" d="M141.152,128.183a.634.634,0,0,1-.634-.634V119.5a.634.634,0,1,1,1.268,0v8.048A.634.634,0,0,1,141.152,128.183Z" transform="translate(-140.518 -118.867)"/>
              </g>
              <g id="Group_429" data-name="Group 429" transform="translate(20.348)">
                <path id="Path_1051" data-name="Path 1051" d="M177.683,98.588a.634.634,0,0,1-.634-.634V89.9a.634.634,0,1,1,1.268,0v8.052A.634.634,0,0,1,177.683,98.588Z" transform="translate(-177.049 -89.268)"/>
              </g>
              <g id="Group_430" data-name="Group 430" transform="translate(30.965 5.667)">
                <path id="Path_1052" data-name="Path 1052" d="M238.374,130.981a.634.634,0,0,1-.634-.634v-8.052a.634.634,0,1,1,1.268,0v8.052A.634.634,0,0,1,238.374,130.981Z" transform="translate(-237.74 -121.661)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PressionPot;
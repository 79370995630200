import React from 'react';

const Shredder  = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="62" viewBox="0 0 53 62">
      <g id="Shredder" transform="translate(-336 -1125)">
        <g id="noun_Grater_1695515" transform="translate(329.768 1126.474)">
          <path id="Path_910" data-name="Path 910" d="M27.43,20.333l-1.7,1.7a.87.87,0,1,0,1.231,1.231l1.68-1.7a.864.864,0,1,0-1.213-1.231Z" transform="translate(-1.143 -1.471)"/>
          <path id="Path_911" data-name="Path 911" d="M35.24,20.346l-1.7,1.7a.876.876,0,0,0,.611,1.491.845.845,0,0,0,.611-.261l1.707-1.7a.9.9,0,0,0-1.231-1.231Z" transform="translate(-1.937 -1.483)"/>
          <path id="Path_912" data-name="Path 912" d="M43.043,20.332l-1.7,1.7a.87.87,0,1,0,1.231,1.231l1.7-1.7a.87.87,0,1,0-1.231-1.231Z" transform="translate(-2.731 -1.47)"/>
          <path id="Path_913" data-name="Path 913" d="M27.444,27.44l-1.7,1.7a.9.9,0,0,0,1.231,1.231l1.68-1.7a.9.9,0,1,0-1.231-1.231Z" transform="translate(-1.157 -2.172)"/>
          <path id="Path_914" data-name="Path 914" d="M35.24,27.462l-1.7,1.7a.9.9,0,0,0,0,1.231.845.845,0,0,0,.611.252.836.836,0,0,0,.611-.252l1.707-1.7a.87.87,0,0,0-1.231-1.231Z" transform="translate(-1.937 -2.194)"/>
          <path id="Path_915" data-name="Path 915" d="M41.956,30.591a.9.9,0,0,0,.62-.252l1.7-1.7a.9.9,0,1,0-1.231-1.231l-1.7,1.7a.9.9,0,0,0,.611,1.482Z" transform="translate(-2.732 -2.169)"/>
          <path id="Path_916" data-name="Path 916" d="M27.43,34.6l-1.7,1.7a.87.87,0,0,0,1.231,1.231l1.68-1.68a.9.9,0,0,0-1.231-1.231Z" transform="translate(-1.143 -2.932)"/>
          <path id="Path_917" data-name="Path 917" d="M35.24,34.583l-1.7,1.7a.876.876,0,0,0,.611,1.491.844.844,0,0,0,.611-.261l1.707-1.68a.877.877,0,0,0-1.231-1.249Z" transform="translate(-1.937 -2.918)"/>
          <path id="Path_918" data-name="Path 918" d="M41.342,37.527a.9.9,0,0,0,1.231,0l1.7-1.68a.9.9,0,0,0-1.231-1.231l-1.7,1.68A.9.9,0,0,0,41.342,37.527Z" transform="translate(-2.729 -2.932)"/>
          <path id="Path_919" data-name="Path 919" d="M27.444,41.69l-1.7,1.7a.9.9,0,0,0,1.231,1.231l1.68-1.7a.9.9,0,1,0-1.231-1.231Z" transform="translate(-1.157 -3.619)"/>
          <path id="Path_920" data-name="Path 920" d="M35.24,41.712l-1.7,1.7a.9.9,0,0,0,0,1.231.845.845,0,0,0,.611.252.836.836,0,0,0,.611-.252l1.707-1.7a.87.87,0,0,0-1.231-1.231Z" transform="translate(-1.937 -3.641)"/>
          <path id="Path_921" data-name="Path 921" d="M41.342,44.619a.9.9,0,0,0,1.231,0l1.7-1.7A.9.9,0,1,0,43.04,41.69l-1.7,1.7A.9.9,0,0,0,41.342,44.619Z" transform="translate(-2.729 -3.619)"/>
          <path id="Path_922" data-name="Path 922" d="M27.43,48.846l-1.7,1.7a.87.87,0,0,0,1.231,1.231l1.68-1.7a.9.9,0,0,0-1.231-1.231Z" transform="translate(-1.143 -4.378)"/>
          <path id="Path_923" data-name="Path 923" d="M35.24,48.832l-1.7,1.7a.876.876,0,0,0,.611,1.491.845.845,0,0,0,.611-.261l1.707-1.7a.87.87,0,0,0-1.231-1.231Z" transform="translate(-1.937 -4.364)"/>
          <path id="Path_924" data-name="Path 924" d="M43.043,48.846l-1.7,1.7a.87.87,0,1,0,1.231,1.231l1.7-1.7a.9.9,0,0,0-1.231-1.231Z" transform="translate(-2.731 -4.378)"/>
          <path id="Path_925" data-name="Path 925" d="M27.444,55.947l-1.7,1.7a.9.9,0,0,0,1.231,1.231l1.68-1.707a.9.9,0,1,0-1.213-1.222Z" transform="translate(-1.157 -5.064)"/>
          <path id="Path_926" data-name="Path 926" d="M35.24,55.972l-1.7,1.7a.9.9,0,0,0,0,1.231.845.845,0,0,0,.611.252.836.836,0,0,0,.611-.252l1.707-1.7a.87.87,0,0,0-1.231-1.231Z" transform="translate(-1.937 -5.09)"/>
          <path id="Path_927" data-name="Path 927" d="M43.054,55.95l-1.7,1.7a.9.9,0,0,0,1.231,1.231l1.7-1.7a.9.9,0,1,0-1.231-1.231Z" transform="translate(-2.742 -5.067)"/>
          <path id="Path_928" data-name="Path 928" d="M46.538,14.353h.9a.9.9,0,0,0,0-1.743H45.469V10.678A5.067,5.067,0,0,0,40.41,5.62H25.7a5.058,5.058,0,0,0-5.049,5.058V12.61H18.687a.9.9,0,0,0,0,1.743h.9L14.257,56.2a2.947,2.947,0,0,0,2.929,3.324H48.946A2.947,2.947,0,0,0,51.874,56.2Zm-2.821-3.675V12.61H40.671V7.39a3.315,3.315,0,0,1,3.046,3.288ZM27.2,12.61V7.363h11.68V12.61Zm-4.789-1.932A3.306,3.306,0,0,1,25.461,7.39v5.22H22.415ZM49.853,57.4a1.213,1.213,0,0,1-.9.413H17.186a1.222,1.222,0,0,1-1.2-1.375l5.391-42.064H44.786l5.391,42.064a1.186,1.186,0,0,1-.323.943Z"/>
        </g>
        <rect id="Rectangle_47" data-name="Rectangle 47" width="53" height="62" transform="translate(336 1125)" fill="none"/>
      </g>
    </svg>
  )
}

export default Shredder;
import React from 'react';

const Bowl  = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="54" height="62" viewBox="0 0 54 62">
  <g id="Bowl" transform="translate(-997 -1125)">
    <g id="noun_Bowl_2578751" transform="translate(990.994 1128.812)">
      <g id="Group_448" data-name="Group 448" transform="translate(6.006 14)">
        <g id="Group_447" data-name="Group 447" transform="translate(0 0)">
          <path id="Path_1081" data-name="Path 1081" d="M27.008.035A53.107,53.107,0,0,0,8.089,3.123a18.66,18.66,0,0,0-5.85,3.458A6.479,6.479,0,0,0,0,11.256c.152,6.915,2.175,13.765,6.415,19.006a23.513,23.513,0,0,0,7.7,6.2v3.458a2.857,2.857,0,0,0,1.8,2.414,13.606,13.606,0,0,0,3.653,1.174,40.567,40.567,0,0,0,7.394.718,40.65,40.65,0,0,0,7.437-.718,13.683,13.683,0,0,0,3.7-1.174,2.9,2.9,0,0,0,1.8-2.414V36.46a23.514,23.514,0,0,0,7.7-6.2c4.24-5.241,6.263-12.091,6.393-19.006a5.857,5.857,0,0,0-2.24-4.675,18.412,18.412,0,0,0-5.85-3.458C41.034,1.188,34.358.035,27.008.035Zm0,1.218A52.081,52.081,0,0,1,45.448,4.276a17.2,17.2,0,0,1,5.458,3.175,5.6,5.6,0,0,1,1.87,3.762,5.042,5.042,0,0,1-1.87,3.479,17.207,17.207,0,0,1-5.458,3.2,52.081,52.081,0,0,1-18.441,3.023A51.992,51.992,0,0,1,8.567,17.889a17.34,17.34,0,0,1-5.48-3.2,5.093,5.093,0,0,1-1.848-3.479A5.211,5.211,0,0,1,3.087,7.451a17.332,17.332,0,0,1,5.48-3.175A51.992,51.992,0,0,1,27.008,1.253Zm-25.53,13.5a9.012,9.012,0,0,0,.761.826,18.64,18.64,0,0,0,5.85,3.436,53.073,53.073,0,0,0,18.919,3.11,52.946,52.946,0,0,0,18.9-3.11,18.393,18.393,0,0,0,5.85-3.436,6.717,6.717,0,0,0,.761-.826A28.879,28.879,0,0,1,46.644,29.5c-4.2,5.154-10.59,8.59-19.637,8.59-9.068,0-15.44-3.436-19.637-8.59A28.717,28.717,0,0,1,1.478,14.758ZM15.33,37.026a28.813,28.813,0,0,0,11.678,2.283,28.705,28.705,0,0,0,11.656-2.283v2.892c0,.478-.348.892-1.174,1.327a12.739,12.739,0,0,1-3.327,1.066,41.706,41.706,0,0,1-7.2.7,41.239,41.239,0,0,1-7.133-.7A13.018,13.018,0,0,1,16.5,41.244c-.826-.435-1.174-.848-1.174-1.327Z" transform="translate(0.002 -0.035)"/>
        </g>
      </g>
    </g>
    <rect id="Rectangle_58" data-name="Rectangle 58" width="54" height="62" transform="translate(997 1125)" fill="none"/>
  </g>
</svg>


  )
}

export default Bowl;